<template>

	<div>
		<van-nav-bar class="navbar" title="业绩详情" fixed left-arrow @click-left="leftReturn" />
		<!-- 第一部分 -->
		<div class="header">
			<div class="leftStatus1">
				租期中
			</div>
			<div class="title">
				<span class="s1">合租 · </span>
				<span class="s2">爱睡觉的覅为活佛熬时间佛饿哦亲朋透气网净额法</span>
			</div>
			<div class="headDetail">
				<div class="h-line">
					<span class="h-title">房源编号：</span>
					<span class="h-detail">6516515</span>
				</div>
				<div class="h-line">
					<span class="h-title">单间数量：</span>
					<span class="h-detail">6516515</span>
				</div>
				<div class="h-line">
					<span class="h-title">委托日：</span>
					<span class="h-detail">6516515</span>
				</div>
				<div class="h-line">
					<span class="h-title">业主交房日：</span>
					<span class="h-detail">6516515</span>
				</div>
				<div class="h-line">
					<span class="h-title">收房年限：</span>
					<span class="h-detail">6516515</span>
				</div>
				<div class="h-line">
					<span class="h-title">总免租期：</span>
					<span class="h-detail">6516515</span>
				</div>
			</div>
		</div>
		<!-- 第二部分 -->
		<div class="smcost">成本信息</div>
		<div class="middle">
			<div class="middledetail">
				<div class="h-line">
					<span class="h-title" style="color: #FF5D3B;">总成本：</span>
					<span class="m-detail" style="color: #FF5D3B;">223元/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">租金成本：</span>
					<span class="m-detail">￥223/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">装修成本：</span>
					<span class="m-detail">￥223/天</span>
				</div>
			</div>
			<div class="headDetail">
				<div class="h-line">
					<span class="h-title">收房价格：</span>
					<span class="h-detail" style="margin-right: 8px;">6516515</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">复利比例：</span>
					<span class="h-detail" style="margin-right: 8px;">6516515</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">装修费用：</span>
					<span class="h-detail" style="margin-right: 8px;">6516515</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">出房总价：</span>
					<span class="h-detail" style="margin-right: 8px;">6516515</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
			</div>
		</div>
		<!-- 第三部分  房间列表 -->
		<div class="btom">
			<div class="smcost2">房间A</div>
			<div class="headDetail" style="padding-bottom: 5px;">
				<div class="h-line">
					<span class="h-title">房源编号：</span>
					<span class="h-detail" style="color: #2276D2;text-decoration:underline;">6516515</span>
				</div>
				<div class="h-line">
					<span class="h-title">签约人：</span>
					<span class="h-detail">嗷嗷嗷</span>
				</div>
				<div class="h-line">
					<span class="h-title">合作人：</span>
					<span class="h-detail">嗷嗷嗷</span>
				</div>
			</div>
			<div class="room">
				<div class="rm1">
					<div class="h-line">
						<span class="h-title">出房价格：</span>
						<span class="h-detail">￥500</span>
					</div>
					<div class="h-line">
						<span class="h-title">签约时长：</span>
						<span class="h-detail">234天</span>
					</div>
				</div>
				<div class="rm1">
					<div class="h-line">
						<span class="h-title">出房周期：</span>
						<span class="h-detail">123天</span>
					</div>
					<div class="h-line">
						<span class="h-title">单间收益：</span>
						<span class="h-detail">￥6465</span>
					</div>
				</div>
			</div>
			<div class="headDetail" style="padding-bottom: 10px;">
				<div class="h-line">
					<span class="h-title">出房周期业绩：</span>
					<span class="h-detail">￥12354</span>
				</div>
				<div class="h-line">
					<span class="h-title">付租期业绩：</span>
					<span class="h-detail">￥12354</span>
				</div>
				<div class="h-line">
					<span class="h-title">出房扣减业绩：</span>
					<span class="h-detail">￥12354</span>
				</div>
				<div class="h-line">
					<span class="h-title">单间总业绩：</span>
					<span class="h-detail">￥12354</span>
				</div>
			</div>
		</div>
<!--		房源总业绩-->
		<div class="smcost">房源总业绩</div>
		<div class="middle">
			<div class="middledetail">
				<div class="h-line">
					<span class="h-title">渠道费用：</span>
					<span class="m-detail">223元/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">其他扣除：</span>
					<span class="m-detail">￥223/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">实际业绩：</span>
					<span class="m-detail">￥223/天</span>
				</div>
			</div>
		</div>

		<!--		员工业绩-->
		<div class="smcost">员工业绩</div>
		<div class="middle">
			<div class="middledetail">
				<div class="h-line">
					<span class="h-title">部门：</span>
					<span class="m-detail">223元/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">员工：</span>
					<span class="m-detail">￥223/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">占比：</span>
					<span class="m-detail">￥223/天</span>
				</div>
				<div class="h-line">
					<span class="h-title">实际业绩：</span>
					<span class="m-detail">￥223/天</span>
				</div>
			</div>
		</div>
		<!-- 垫底的 -->
		<div style="width: 100%;height: 50px;"></div>
	</div>
	

</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		queryBaseData,
		userLeaseChangeList
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				show: false,
				showPicker: false,
				isOccupationShow: false,
				loading: false,
				finished: false,
				certificateType: '',
				isOccupationShow: false,
				defaultIndex: '',
				swi: true,
				isLoading: false,
				totalPage: '',
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {
		},
		//初始化
		mounted() {
		},
		methods: {
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}
	
	.header,.middle,.btom{
		border-radius: 8px;
		width: 90%;
		background-color: white;
		margin: 0 auto;
		margin-top: 80px;
	}
	
	.middle,.btom{margin-top: 0px;}
	
	.botm{
		border-bottom: 1px solid #f4f4f4;
		margin-bottom: 150px;
	}
	
	.leftStatus1 {
		width: 43px;
		height: 16px;
		line-height: 16px;
		color: rgba(255, 255, 255, 1);
		font-size: 10px;
		text-align: center;
		/* float: left; */
		border-top-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	
	.leftStatus1 {
		background-image: linear-gradient(to right, #66CB94, #28D3B0);
	}
	
	.title{
		padding-top: 8px;
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 12px;
		border-bottom: 1px solid #f4f4f4;
	}
	
	.s1{
		font-weight: 600;
		color: #FF5D3B;
		font-size: 15px;
	}
	
	.s2{
		font-weight: 600;
		font-size: 15px;
	}
	
	.headDetail,.middledetail{
		margin-top: 10px;
		padding-left: 25px;
		padding-bottom: 25px;
	}
	
	.middledetail{
		padding-top: 15px;
		border-bottom: 1px solid #f4f4f4;
		padding-bottom: 10px;
	}
	
	.h-line{
		position: relative;
		margin-bottom: 4px;
	}
	
	.h-title{
		font-weight: 600;
		font-size: 14px;
	}
	
	.h-detail{
		font-size: 12px;
		color: #ACACAC;
	}
	
	.m-detail{
		font-weight: 600;
		font-size: 14px;
	}
	
	.smcost{
		color: #FF5D3B;
		margin-left: 20px;
		font-weight: 600;
		font-size: 12px;
		margin-top: 20px;
	}	
	
	.smcost2{
		color: #FF5D3B;
		margin-top: 20px;
		font-size: 14px;
		padding-top: 15px;
		padding-left: 15px;
		padding-bottom: 15px;
		font-weight: 600;
		border-bottom: 1px solid #f4f4f4;
	}
	
	.room{
		border-top: 1px solid #f0f0f0;
		padding-left: 25px;
		padding-top: 15px;
		border-bottom: 1px solid #f0f0f0;
		height: 80px;
		/* background-color: red; */
	}
	
	.rm1{
		width: 48%;
		float: left;
		height: 90px;
		/* background-color: red; */
	}
	
	.edit{
		position: absolute;
		top: 3px;
	}
	
	.editimg{
		width: 15px;
		height: 15px;
	}
</style>
